/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '../../@core/scss/base/bootstrap-extended/include';
@import '../../@core/scss/base/components/variables';
// @import '../../base/pages/assets';

@include media-breakpoint-up(md) {
  @media (min-width:1200px) and (max-width:1590px) {
    code {
      font-size: 80%;
    }

    .vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
      width: 15% !important;
    }

    .main-menu .navbar-header,
    .main-menu .navbar-header .navbar-brand .brand-logo img {
      width: 100% !important;
    }

    .main-menu,
    .main-menu .navbar-header .navbar-brand,
    .navbar-nav {
      height: 100% !important;
    }

    .header-navbar.floating-nav {
      width: auto;
      left: 0;
    }

    .content {
      margin-left: 15% !important;
    }

    .weatherWidget {
      width: calc(-18% + 100vw) !important;
      transform: translate(-50%, -46%) !important;
      pointer-events: none;
    }

    .vendorTableName {
      pointer-events: none;
    }
  }

  .responsiveDisplay {
    @media (max-width: 1408px) {
      width: 100%;
    }
  }

  // .responsiveWidth {
  //   @media (max-width: 1590px) {
  //     width: auto;
  //   }
  // }

  // navbar tabs pills
  .profile-header-nav {
    .profile-tabs {
      margin-left: 11.1rem;
    }
  }

  .hero {
    padding: 1rem;
    height: 50vh;
    background-image: linear-gradient(to right,
        rgba(255, 255, 255, 0.0),
        rgba(161, 161, 161, 0.0),
        rgba(161, 161, 161, 0.0),
        rgba(161, 161, 161, 0.0),
        #23c8f19c,
        #008ae6b7,
        #0f71f1d3, );

  }

  .heroBtn {
    border: 0.25px !important;
    border-radius: 10px;
  }

  // Base Stylings

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden
  }

  .tagtekaBtn {
    background-color: #2495d1 !important;
    border-color: #2495d1 !important;
  }

  .btn-close {
    // top: 5px;
    // right: 8px;
    margin: 1rem 1rem -0.4rem auto !important;
  }

  .customBadge {
    width: 100px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px;
  }

  // .form-switch input[type="checkbox"] {
  //   border-radius: 5px;
  // }

  .boxCheckedP {
    background-color: #2984E6 !important;
    border-color: #2984E6 !important;
    box-shadow: 0 2px 4px 0 rgba(32, 67, 236, .4);
  }

  .boxCheckedE {
    background-color: #F0D868 !important;
    border-color: #F0D868 !important;
    box-shadow: 0 2px 4px 0 rgba(32, 67, 236, .4);
  }

  .boxCheckedH {
    background-color: #40BD83 !important;
    border-color: #40BD83 !important;
    box-shadow: 0 2px 4px 0 rgba(32, 67, 236, .4);
  }

  .boxCheckedF {
    background-color: #FF6862 !important;
    border-color: #FF6862 !important;
    box-shadow: 0 2px 4px 0 rgba(32, 67, 236, .4);
  }

  .boxCheckedC {
    background-color: #999999 !important;
    border-color: #999999 !important;
    box-shadow: 0 2px 4px 0 rgba(32, 67, 236, .4);
  }

  .showBox {
    display: block
  }

  .hideBox {
    display: none;
  }

  .btn-primary {
    background-color: black !important;
    border-color: black !important;

    &:active,
    &:hover,
    &:focus {
      background-color: black !important;
      border-color: black !important;
    }

    &:hover,
    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0 8px 20px -8px #00000082 !important;
    }
  }

  .dropdown-item:hover {
    background-color: rgba(137, 143, 177, 0.12);
    color: black;

    & svg {
      stroke: black !important
    }
  }

  .text-body[href]:hover {
    color: black !important;
  }

  .notBold {
    font-weight: 400;
  }

  .card-company-table {
    border: 0.5px solid rgba(34, 41, 47, .125);
    box-shadow: none !important
  }

  .progress-bar-danger progress {
    width: 50%;
  }

  .form-check-input:checked {
    background-color: black;
    border-color: black;
  }

  .form-check-primary .form-check-input:checked {
    background-color: #2984E6;
    border-color: #2984E6 !important;
  }

  .form-check-primary .form-check-input:not(:disabled):focus {
    border-color: #2984E6
  }

  .form-check-success .form-check-input:checked {
    background-color: #40BD83;
    border-color: #40BD83 !important;
  }

  .form-check-success .form-check-input:not(:disabled):focus {
    border-color: #40BD83
  }

  .form-check-danger .form-check-input:checked {
    background-color: #FF6862;
    border-color: #FF6862 !important;
  }

  .form-check-danger .form-check-input:not(:disabled):focus {
    border-color: #FF6862
  }

  .form-check-dark .form-check-input:checked {
    background-color: #999999;
    border-color: #999999 !important;
  }

  .form-check-dark .form-check-input:not(:disabled):focus {
    border-color: #999999
  }

  .form-check-warning .form-check-input:checked {
    background-color: #F0D868;
    border-color: #F0D868 !important;
  }

  .form-check-warning .form-check-input:not(:disabled):focus {
    border-color: #F0D868
  }

  .fc-daygrid-event-dot {
    background-image: url(https://tagteka-assets.s3.us-east-2.amazonaws.com/TAGTEKA_LogoOnlyGreyscale.png) !important;
    height: 10px !important;
    width: 10px !important;
    background-size: contain !important;
    background-position: center !important;
    border: none !important;
    border-radius: 0 !important;
    background-repeat: no-repeat;
    margin-right: 6px !important
  }

  .bg-light-warning {
    color: #F0D868 !important;
  }

  .bg-light-primary {
    color: #2984E6 !important
  }

  .fc-daygrid-dot-event .fc-event-title {
    text-overflow: ellipsis;
  }

  // .dropdown-item a {
  //   color: #6e6b7b !important;
  //   &:hover {
  //     color: black !important
  //   }
  // }

  // Login Page
  .loginLogo {
    display: none !important;

    @media (max-width: 991px) {
      display: block !important
    }
  }

  .loginLogoCenter {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 550px;
    background-color: #ffffffba;
    padding: 100px;

    @media (max-width: 991px) {
      display: none
    }
  }

  // Dashboard Stylings

  .accountIcon {
    font-size: 2rem;
    margin-right: 10px;
    color: rgb(57, 58, 61);

    &:hover {
      filter: drop-shadow(gray 1px 1px 2px);
      color: black;
    }
  }

  .weatherWidget {
    width: calc(-260px + 100vw);
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    @media (max-width: 1260px) {
      transform: translate(-50%, -45%);
    }

    @media (max-width: 1199px) {
      position: relative;
      width: 100%;
    }
  }

  .navbar-light .navbar-nav .active.nav-link {
    background-color: #e8e8e8;
    color: #393a3d;
  }

  .emergencyCard {
    @media (max-width: 1590px) {
      max-width: 200px !important;

      h4 {
        font-size: 1.5rem !important;
      }

    }
  }

  .emergencyButton {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 60px;
    width: 60px;
    // height: 30%;
    // width: 33%;
    margin: 0 2px;
    // @media (max-width: 1590px) {
    //   width: 100%;
    //   height: auto;
    //   background-size: contain;
    //   margin: 0px;
    //   margin-top: 15px;
    // }
  }

  .recentTaggedAssets {
    @media (max-width: 1590px) {
      width: calc(100%)
    }
  }

  .emergencyBackdrop {
    background-color: #770000;
  }

  .serviceBackdrop {
    background-color: #3c3c3c;
  }

  .btnBackground {
    // background-color: rgb(249, 249, 249);
    // background: radial-gradient(circle, #f9f9f9 57%, #f0f0f0 100%);
    background: rgb(237, 237, 237);
    background: linear-gradient(0deg, #f5f5f5 0%, #fff 40%, #fff 60%, #f5f5f5 100%);
    width: 100%;
    height: 100%;
    box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 5px 0px;
    border-radius: 5px;
    color: rgb(94, 88, 115) !important;
    border: none;

    &:hover {
      // background-color: rgb(179, 179, 179);
      // background: radial-gradient(circle, #acacac 85%, #f7f7f7 100%);
      background: rgb(172, 172, 172);
      // background: linear-gradient(0deg, rgba(234, 234, 234, 1) 0%, rgba(173, 173, 173, 1) 15%, rgba(150, 150, 150, 1) 29%, rgba(140, 140, 140, 1) 46%, rgba(138, 138, 138, 1) 50%, rgba(140, 140, 140, 1) 54%, rgba(150, 150, 150, 1) 70%, rgba(173, 173, 173, 1) 85%, rgba(234, 234, 234, 1) 100%);
      background: linear-gradient(0deg, #8a8a8a 0%, #dfdfdf 95%);
      color: white !important;
    }
  }

  // .cardHeader {
  //   text-align: 'center' !important;
  //   width: '100%' !important; 
  //   text-transform: 'uppercase' !important;
  //   font-size: 1.5rem !important;
  //   padding-bottom: 0.5rem !important
  // }

  .progress-bar-container {
    display: flex;
    flex-direction: row;
  }

  .progress-bar {
    border-radius: 2px !important;
  }

  .progress {
    border-radius: 2px !important;
    border-bottom: solid 0.5px lightgray;
  }

  // Calendar Page Stylings

  .modal-center-calendar {
    margin-top: 10%;
  }

  .modal-content-size {
    min-width: 1200px !important;
  }

  .left-col-modal {
    border-right: rgb(57, 58, 61);
  }

  // Asset Page Stylings

  .viewLink {
    text-transform: uppercase;
    background-color: #333 !important;
    padding: 0.6rem;
    font-size: 0.8rem;
  }

  .requestServiceBtn,
  .requestMaintenanceBtn {
    text-transform: uppercase;
    background-color: #333 !important;
    min-width: 139px;
  }

  .requestServiceBtn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .requestMaintenanceBtn {
    padding: 0.6rem;
    font-size: 0.8rem;
  }

  .nav-pills .nav-link.active {
    background-color: #333;
    border-color: #333;
    box-shadow: 0 4px 18px -4px rgba(50, 53, 56, 0.65);
  }

  .carousel.carousel-slider {
    border-radius: 5px;
  }

  .editAssetBtn {
    background-color: #FFFFFF;
    box-shadow: 0 0 2px #AAAAAA;
    margin-right: 20px;
    margin-left: -20px !important
  }

  .addRecordBtn {
    background-color: #333 !important;
  }

  .backBtn {
    border-color: #333 !important;
    background-color: #f5f5f5 !important;
    color: #333 !important;
    box-shadow: 0 0 2px #AAAAAA;
    margin-bottom: 15px;

    &:hover {
      background-color: #333 !important;
      color: #fff !important
    }

    &:active {
      background-color: #333 !important;
      color: #fff !important
    }
  }

  // Vendor Page Stylings
  .vendorTableName {
    pointer-events: none;
  }

  .vendorDisplay {
    border-radius: .428rem;
    margin-bottom: 2rem;
    border: 1px solid #ebe9f1 !important;
    position: relative;
    // margin: 0 auto;
    // display: block;
    // width: 85%;
    // box-shadow: 0px 0px 8px 0px #686868;
  }

  .borderHeading {
    height: 80px;
    background-color: white;
    border-bottom: 1px solid #ebe9f1;
    // background: #d5d5d5;
    // margin: 50px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1.5rem;
    // padding: 0 15px;
    // input {
    //   margin-right: 5px;
    // }
  }

  .vendorTitle {
    font-weight: 700;
    text-transform: uppercase;
    width: 85%;
    text-align: center;
  }

  .vendorForm {
    text-align: center;
    // box-shadow: 0px 1px 3px 2px gray;
    // margin: 20px auto;
    // width: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #d8d6de;
    margin-left: auto;
    width: 270px;

    input {
      width: 210px;
      padding: 5px 0px;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: 0.4px;
      // width: calc(95% - 20px);
      // padding: 15px 20px 10px;
      // font-size: 1.5rem;
      border: none;
      outline: none;
    }

    i {
      font-size: 1rem;
      // font-size: 1.5rem;
    }
  }

  .vendorCard {
    margin-top: 45px;
  }

  .vendorCheckbox {
    margin: 0;
    padding: 0;

    // display: flex;
    // justify-content: center;
    // margin: 40px 0;
    li {
      list-style: none;
      display: flex;
      // margin: 0 20px;
    }

    .filterLabel {
      font-size: 1rem;
      // text-transform: uppercase;
      margin-left: 0.75rem;
      font-weight: 500;
      letter-spacing: 0.4px;
    }

    .filterBox {
      // height: 20px;
      // width: 20px;
      // margin: 0 15px;
      margin: 2px 0px;
      width: 1.285rem;
      height: 1.285rem;
      background-color: white;
      border-radius: 3px;
      border: 1px solid #d8d6de;
    }
  }

  .logoImg {
    background-color: white;
    margin: -8px 0 -75px;
    //margin: 0 0 -60px;
    width: 90px;
    height: 88px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    padding: 8px;

    &:hover {
      border: 3px solid #a8a8a8 !important;
      box-shadow: 0px 0px 10px 1px #9b9b9b;
    }
  }

  .clicked {
    border: 3px solid #a8a8a8 !important;
    box-shadow: 0px 0px 10px 1px #9b9b9b;
  }

  .profileLink {
    padding: 25px 0px;
    margin: 0 9px;
    color: #999 !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background: linear-gradient(0deg, #f5f5f5, #fff 25%, #fff 60%, #f5f5f5);
    box-shadow: 0 4px 5px 0 rgba(34, 41, 47, .1);

    &:hover {
      background: linear-gradient(0deg, #868585, #eeeded 95%);
      background-color: rgba(0, 0, 0, 0);
      color: white !important;
      border: none !important;
    }

    // color: #999 !important;
    // box-shadow: 0px 0px 4px .5px #686868;
    // text-transform: uppercase;
    // border-radius: 50%;
    // width: 65px;
    // height: 65px;
    // padding: 0;
    // margin: 0 8px;
    // &:hover {
    //   border: 1px solid #d8d6de !important;
    // }
  }

  .requestButton,
  .requestLink {
    text-align: center;
    font-weight: 500;
    line-height: 5px;
    border-radius: 5px;
    background: linear-gradient(0deg, #f5f5f5, #fff 25%, #fff 60%, #f5f5f5);
    box-shadow: 0 4px 5px 0 rgba(34, 41, 47, .1) !important;
    padding: 20px 0px;
    width: 230px;
    text-transform: uppercase;
    color: #999 !important;
    border: none;

    &:hover {
      background: linear-gradient(0deg, #868585, #eeeded 95%);
      background-color: rgba(0, 0, 0, 0);
      color: white !important;
    }

    // background-color: #fff;
    // text-transform: uppercase;
    // color: #999 !important;
    // box-shadow: 0px 0px 4px .5px #686868;
    // border-radius: 10px;
    // &:hover {
    //   border: 1px solid #d8d6de !important;
    // }
  }

  // Analytics Page
  .activeFilter {
    background-color: #333;
    color: #fff;
  }

  // Settings Page
  .plan-statistics .progress-bar {
    background-color: #333;
  }

  .plan-statistics .progress {
    background-color: #3333331e;
  }

  // Media Queries
  @media print {
    .noPrint {
      display: none;
    }

    @page {
      size: A4 landscape;
      scale: 0.61;
    }
  }

  //  .vendorButton {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   li {
  //     list-style: none;
  //     margin: 15px;
  //   }
  //   button {
  //     background-color: rgb(41, 132, 230) !important;
  //     font-size: 2rem;
  //     padding: 30px 34px;
  //     list-style: none;
  //     border-radius: 14px;
  //     box-shadow: 1px 1px 3px 2px #a2a2a2;
  //   }
  //  }


}

@include media-breakpoint-up(sm) {

  // Base Styles
  .tagtekaBtn {
    background-color: #2495d1 !important;
    border-color: #2495d1 !important;
  }

  .btn-close {
    // top: 5px;
    // right: 8px;
    margin: 1rem 1rem -0.4rem auto !important;
  }

  .customBadge {
    width: 100px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
    padding: 2px 5px;
  }

  .header-navbar .navbar-container ul.navbar-nav li i.ficon:hover,
  .header-navbar .navbar-container ul.navbar-nav li svg.ficon:hover {
    color: black;
  }

  .btn-primary {
    background-color: black !important;
    border-color: black !important;

    &:active,
    &:hover,
    &:focus {
      background-color: black !important;
      border-color: black !important;
    }

    &:hover,
    &:hover:not(.disabled):not(:disabled) {
      box-shadow: 0 8px 20px -8px #00000082 !important;
    }
  }

  .dropdown-item:hover {
    background-color: rgba(137, 143, 177, 0.12);
    color: black;

    & svg {
      stroke: black !important
    }
  }

  .text-body[href]:hover {
    color: black !important;
  }

  .notBold {
    font-weight: 400;
  }

  .form-check-input:checked {
    background-color: black;
    border-color: black;
  }

  .nav-pills .nav-link.active {
    background-color: #333;
    border-color: #333;
    box-shadow: 0 4px 18px -4px rgba(50, 53, 56, 0.65);
    ;
  }

  //Header Styles
  .accountIcon {
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    margin-right: 10px;
    color: rgb(57, 58, 61);

    &:hover {
      filter: drop-shadow(gray 1px 1px 2px);
      color: black;
    }
  }

  .emergencyButton {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 60px;
    width: 60px;
    margin: 0 2px;
  }

  //Dashboard Styles
  .progress-bar-container {
    display: flex;
    flex-direction: row;
  }

  .progress-bar {
    border-radius: 2px !important;
  }

  .progress {
    border-radius: 2px !important;
    border-bottom: solid 0.5px lightgray;
  }

  .requestServiceBtn,
  .requestMaintenanceBtn {
    text-transform: uppercase;
    background-color: #333 !important;
    min-width: 139px;
  }

  .requestServiceBtn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .requestMaintenanceBtn {
    padding: 0.6rem;
    font-size: 0.8rem;
  }

  .recentTaggedAssets {
    @media (max-width: 768px) {
      width: calc(100%)
    }
  }

  .weatherWidget {
    pointer-events: none;
  }

  //Asset Page Styles
  .backBtn {
    border-color: #333 !important;
    background-color: #f5f5f5 !important;
    color: #333 !important;
    box-shadow: 0 0 2px #AAAAAA;
    margin-bottom: 15px;

    &:hover {
      background-color: #333 !important;
      color: #fff !important
    }

    &:active {
      background-color: #333 !important;
      color: #fff !important
    }
  }

  // Vendor Page Styles
  .vendorTableName {
    pointer-events: none;
  }
}